import React, { FC, ReactNode } from 'react';
import { graphql } from 'gatsby';

import { GatsbySeo } from 'gatsby-plugin-next-seo/lib';
import { useIntl } from 'gatsby-plugin-intl';

import { AllWpMapPlaces, PlaceEdge } from 'types';
import localeHelper from '@helpers/locale.json';
import { getLink } from '@helpers/linking.helper';
import { PageType } from '@constants/pageType.constants';

import Map from '@components/map/Map.component';

import Thumbnail from '@assets/thumbnails/avionaut_thumbnail.jpg'

import s from './Stores.module.scss';
import { LogoJsonLd } from 'gatsby-plugin-next-seo';

interface IStoresTemplate {
  places: any;
  searchBoxTitle: ReactNode;
  backToTheList: string;
  location: {
    search: string;
  }
  data: {
    allWpMapPlace: AllWpMapPlaces,
  }
}

const StoresTemplate: FC<IStoresTemplate> = (props) => {
  const intl = useIntl();

  const { allWpMapPlace } = props.data;

  const comparePlaces = (firstPlace: PlaceEdge, secondPlace: PlaceEdge): number => {

    if (intl.locale === localeHelper.locales.de) {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === 'Austria' ||
        firstPlace.node.placeData.locLocation.country === 'Szwajcaria' )) &&
        ( secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale] ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale]))
      {
        return -1;
      } else {
        return 1;
      }
    }
    if (intl.locale === localeHelper.locales.fr) {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale])  &&
        (secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale]) ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale])) 
      {
        return -1;
      } else {
        return 1;
      }
    }
    if (intl.locale === localeHelper.locales.en) {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === 'Ecuador') &&
        (secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale]) ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale])) {
        return -1;
      } else {
        return 1;
      }
    }
    if (intl.locale === localeHelper.locales.cz) {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale]) &&
        (secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale] ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale]))) {
        return -1;
      } else {
        return 1;
      }
    }
    if (intl.locale === localeHelper.locales.es) {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === 'Portugal') &&
        (secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale] ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale]))) {
        return -1;
      } else {
        return 1;
      }
    }
    else {
      if (((firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryEN[intl.locale] ||
        firstPlace.node.placeData.locLocation.country === localeHelper.localeToCountryPL[intl.locale]) &&
     (   secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryEN[intl.locale] ||
        secondPlace.node.placeData.locLocation.country !== localeHelper.localeToCountryPL[intl.locale]))) {
        return -1;
      } else {
        return 1;
      }
    }
  };

  const placesEdges = [...allWpMapPlace.edges];
  const sortedEdges = placesEdges.sort(comparePlaces);

  return (
    <>
      <LogoJsonLd
        logo={'https://avionaut.com/avionaut.svg'}
        url={`https://avionaut.com${getLink(intl.locale, PageType.START)}`}
      />
      <GatsbySeo
        language={intl.locale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: `https://avionaut.com${getLink(intl.locale, PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'en',
            href: `https://avionaut.com${getLink('en', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'pl',
            href: `https://avionaut.com${getLink('pl', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'de',
            href: `https://avionaut.com${getLink('de', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'cz',
            href: `https://avionaut.com${getLink('cz', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'hr',
            href: `https://avionaut.com${getLink('hr', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'es',
            href: `https://avionaut.com${getLink('es', PageType.SHOPS)}/`,
          },
          {
            hrefLang: 'fr',
            href: `https://avionaut.com${getLink('fr', PageType.SHOPS)}/`,
          },
        ]}
        canonical={`https://avionaut.com${getLink(intl.locale, PageType.SHOPS)}/`}    
        title={`Avionaut - ${intl.formatMessage({ id: 'metaTitle__stores' })}`}
        description={intl.formatMessage({ id: 'metaDescription__stores' })}
        metaTags={[
          {
            property: 'thumbnail',
            content: Thumbnail,
          },
          {
            property: 'og:title',
            content: intl.formatMessage({ id: 'metaTitle__stores' }),
          },
          {
            property: 'twitter:title',
            content: intl.formatMessage({ id: 'metaTitle__stores' }),
          },
          {
            property: 'twitter:description',
            content: intl.formatMessage({ id: 'metaDescription__stores' }),
          },
        ]}
      />
      <div className={s.stores}>
        <Map
          places={sortedEdges}
          searchParams={props.location.search}
          placeholder={intl.formatMessage({ id: 'storeSearch__placeholder' })}
          searchBoxTitle={intl.formatMessage({ id: 'stores__panelHeader' })}
          backToTheList={intl.formatMessage({ id: 'stores__scrollBack' })}
        />
      </div>
    </>
  );
};

export const query = graphql`
  query MapPlaces {
    allWpMapPlace {
      totalCount
      edges {
        node {
          id
          placeData {
            locAddress
            locName
            locPhone
            locUrlfacebook
            locUrlinstagram
            locUrllinkedin
            locUrlstore
            locLocation {
              city
              country
              latitude
              longitude
              placeId
            }
            possibleRental
          }
        }
      }
    }
  }

`;

export default StoresTemplate;
